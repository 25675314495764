import { useReducer, useEffect, useRef } from 'react'
import {
    FormSection,
    UniversalDataTable,
    UniversalGoogleMap,
    UniversalReactSorter,
    Title,
} from '../../../../UniversalComponents'
import {
    Grid,
    Button,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    TextField,
} from '@mui/material'
import BoatInsightCards from './BoatConfigInsightsCards'
import BoatConfigInsightsHistogram from './BoatConfigInsightsHistogram'
import BoatConfigInsightsTelemetry from './BoatConfigInsightsTelemetry'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useSocket } from '../../../../../context/SocketContext'
import { useToastMessage } from '../../../../../context/ToastPopUpContext'
import { useAuth } from '../../../../../context/AuthenticationContext'
import UniversalImageDisplayer from '../../../../UniversalComponents/UniversalImageDisplay'
import { getBoatTelemetry } from '../../../../../apiRoutes'
import { parseTelemetryData } from '../../../../../utilities/functionTools'

const BoatConfigurationInsights = ({ row, openEdit, interval, editBoat }) => {
    const reducer = (state, newState) => ({ ...state, ...newState })

    const defaultState = {
        clusterList: [],
        draggable: [],
        telemetry: [],
        histogramTelemetry: [],
        gps: { lat: 28, lng: -81 },
        signals: [],
        isLoaded: true,
        loading: true,
        filteredSignals: [],
        boatModelImage: '',
        boatInsights: row?.boatModel?.signalProfile?.boatInsights,
    }

    const [state, setState] = useReducer(reducer, defaultState)
    const {
        telemetry,
        histogramTelemetry,
        signals,
        gps,
        filteredSignals,
        boatModelImage,
        isLoaded,
        loading,
        boatInsights,
    } = state

    const { fullHin, boatModel, manufacturer, nickname, manufacturerName } = row
    const { socket } = useSocket()
    const authentication = useAuth()
    const { auth } = authentication
    const { userPreferences, activeManufacturer } = auth
    const setToastMessage = useToastMessage()
    const visibleColumns = ['field', 'value']
    const defaultColumnVisibility = {
        field: true,
        value: true,
    }

    const formattedData =
        telemetry &&
        Object.keys(telemetry)
            .map((key) => ({
                id: `${key}.id`,
                field: key,
                value: telemetry[key],
            }))
            .filter(({ field }) => field !== 'signals')
    const style = {
        width: '100%',
        height: 500,
        backgroundColor: 'inherit',
    }

    const refreshCount = useRef(0)

    const getData = async (id: string) => {
        try {
            const getHistogramTelemetryData = await getBoatTelemetry({
                _id: id,
                telemetryType: 'combined',
            })
            if (
                getHistogramTelemetryData &&
                getHistogramTelemetryData.length !== 0
            ) {
                const parsedTelemetryData = parseTelemetryData(
                    getHistogramTelemetryData
                )
                setState({
                    histogramTelemetry: parsedTelemetryData,
                    loading: false,
                })
            }
        } catch (error: any) {
            setToastMessage(error, 'error')
            console.error(error, 'error')
        }
    }

    useEffect(() => {
        setState({ boatModelImage: boatModel?.modelImageUrl })
        socket.emit('liveData', { hin: fullHin, type: 'webSignals' })
        socket.on('liveDataRes', (data) => {
            if (data) {
                setState({
                    telemetry: data,
                    signals: data.signals,
                    gps: { lat: data?.GPS_Latitude, lng: data?.GPS_Longitude },
                })
            }

            refreshCount.current = refreshCount.current + 1
            return refreshCount
        })

        return () => socket.emit('leave:room', '')
    }, [])

    useEffect(() => {
        if (row?.id && histogramTelemetry.length === 0) {
            getData(row?.id)
        }
    }, [histogramTelemetry])

    useEffect(() => {
        interval.current = setInterval(() => {
            socket.emit('liveData', { hin: fullHin, type: 'webSignals' })
        }, 10000)

        return () => {
            clearInterval(interval.current)
        }
    }, [interval])

    useEffect(() => {
        if (formattedData && formattedData?.length !== 0) {
            setTimeout(() => setState({ isLoaded: false }), 1000)
        }
    }, [formattedData])

    const telemetryConversion = (signals, userPreferences) => {
        if (!userPreferences.imperial) {
            return signals
        }

        const updatedSignals = signals.map((signal) => {
            if (
                signal?.unitOfMeasurement === '°C' &&
                !isNaN(signal?.description)
            ) {
                const convertedValue = (
                    (parseFloat(signal?.description) * 9) / 5 +
                    32
                ).toFixed(2)
                const convertedUOM = '°F'
                return {
                    ...signal,
                    convertedValue,
                    convertedUOM,
                }
            }
            if (
                signal?.unitOfMeasurement === 'LPH' &&
                !isNaN(signal?.description)
            ) {
                const convertedValue = (
                    parseFloat(signal?.description) / 3.8
                ).toFixed(2)
                const convertedUOM = 'GPH'
                return {
                    ...signal,
                    convertedValue,
                    convertedUOM,
                }
            }
            if (
                signal?.unitOfMeasurement === 'M' &&
                !isNaN(signal?.description)
            ) {
                const convertedValue = (
                    parseFloat(signal?.description) * 3.28084
                ).toFixed(2)
                const convertedUOM = 'Ft'
                return {
                    ...signal,
                    convertedValue,
                    convertedUOM,
                }
            }
            return signal
        })

        return updatedSignals
    }

    useEffect(() => {
        const convertedValues = telemetryConversion(signals, userPreferences)
        if (convertedValues && convertedValues.length !== 0) {
            setState({ filteredSignals: convertedValues })
        }
    }, [signals, userPreferences])

    const manufacturerCheck = activeManufacturer === manufacturerName

    return (
        <Grid container spacing={2} sx={{ marginTop: 8 }}>
            {formattedData && formattedData?.length !== 1 ? (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ marginTop: -40 }}>
                                {manufacturerCheck && editBoat && (
                                    <FormSection>
                                        <Grid item xs={12}>
                                            <Button
                                                onClick={openEdit}
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                sx={{
                                                    height: 55,
                                                }}
                                            >
                                                Edit Boat Info
                                            </Button>
                                        </Grid>
                                    </FormSection>
                                )}
                            </Grid>
                            <Grid item xs={12} md={4}>
                                {(isLoaded && (
                                    <FormSection title="Boat Model">
                                        <Grid item xs={12}>
                                            <Typography align="center">
                                                <CircularProgress
                                                    color="primary"
                                                    size={'100px'}
                                                />
                                            </Typography>
                                        </Grid>
                                    </FormSection>
                                )) || (
                                    <FormSection title="Boat Model">
                                        <Grid item xs={12}>
                                            <UniversalImageDisplayer
                                                url={
                                                    boatModelImage ||
                                                    row?.modelImageUrl
                                                }
                                                alt={`${boatModel?.modelYear} - ${boatModel?.modelYear}`}
                                                title={
                                                    manufacturer?.manufacturerName ||
                                                    row?.manufacturer
                                                }
                                                subtitle={`${
                                                    boatModel?.modelYear ||
                                                    row?.modelYear
                                                } - ${
                                                    boatModel?.model ||
                                                    row?.model
                                                }`}
                                                width={'55%'}
                                                height={'55%'}
                                            />
                                        </Grid>
                                    </FormSection>
                                )}
                                <FormSection title="Gps Data">
                                    <Grid item xs={12}>
                                        <UniversalGoogleMap
                                            gps={gps}
                                            style={style}
                                        />
                                    </Grid>
                                </FormSection>
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <Grid item xs={12}>
                                    <FormSection title={`${fullHin}'s Details`}>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{ marginBottom: 5 }}
                                        >
                                            <TextField
                                                label={'Signal Profile'}
                                                value={
                                                    boatModel?.signalProfile
                                                        ?.profileName ||
                                                    row.profileName
                                                }
                                                disabled={true}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            sx={{ marginBottom: 5 }}
                                        >
                                            <TextField
                                                label={'Nickname'}
                                                value={nickname}
                                                disabled={true}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                    </FormSection>
                                </Grid>
                                <FormSection title={'Live Signal Data'}>
                                    <Grid item xs={12}>
                                        <UniversalReactSorter
                                            list={filteredSignals}
                                            setList={(drag) =>
                                                setState({
                                                    filteredSignals: drag,
                                                })
                                            }
                                            group="fixed"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                flexWrap: 'wrap',
                                                marginTop: 5,
                                                width: '100%',
                                            }}
                                            disabled={true}
                                        >
                                            {filteredSignals?.length !== 0 &&
                                                filteredSignals?.map(
                                                    ({
                                                        id,
                                                        image,
                                                        description,
                                                        title,
                                                        unitOfMeasurement,
                                                        convertedValue,
                                                        convertedUOM,
                                                    }) => (
                                                        <BoatInsightCards
                                                            image={image}
                                                            key={`${id}.card`}
                                                            description={
                                                                convertedValue
                                                                    ? convertedValue
                                                                    : description
                                                            }
                                                            unitOfMeasurement={
                                                                convertedUOM
                                                                    ? convertedUOM
                                                                    : unitOfMeasurement
                                                            }
                                                            title={title}
                                                            disabled={true}
                                                        />
                                                    )
                                                )}
                                        </UniversalReactSorter>
                                    </Grid>
                                </FormSection>
                                {boatInsights && boatInsights.length !== 0 && (
                                    <BoatConfigInsightsTelemetry
                                        boatInsights={boatInsights}
                                        telemetry={telemetry}
                                    />
                                )}
                                <Grid item xs={12}>
                                    <BoatConfigInsightsHistogram
                                        telemetry={histogramTelemetry}
                                        loading={loading}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion elevation={4}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography
                                        variant="h5"
                                        color={'primary'}
                                        align="center"
                                    >
                                        Live Data Table
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid item xs={12}>
                                        <Title
                                            title={`${fullHin} Live Data`}
                                            variant="h4"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ marginTop: -10 }}>
                                        <UniversalDataTable
                                            data={formattedData}
                                            visibleColumns={visibleColumns}
                                            getRowData={() => {}}
                                            getColumnHeaderVisibility={() => {}}
                                            columnsAvailable={
                                                defaultColumnVisibility
                                            }
                                            checkboxSelection={false}
                                        />
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Grid item xs={12} sx={{ margin: 5, textAlign: 'center' }}>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                            <FormSection title="Boat Model">
                                <Grid item xs={12}>
                                    <UniversalImageDisplayer
                                        url={
                                            boatModelImage || row?.modelImageUrl
                                        }
                                        alt={`${boatModel?.modelYear} - ${boatModel?.modelYear}`}
                                        title={
                                            manufacturer?.manufacturerName ||
                                            row?.manufacturer
                                        }
                                        subtitle={`${
                                            boatModel?.modelYear ||
                                            row?.modelYear
                                        }  ${boatModel?.model || row?.model}`}
                                    />
                                </Grid>
                            </FormSection>
                        </Grid>
                        <Grid item md={8} xs={12} sx={{ textAlign: 'center' }}>
                            <FormSection
                                title="Edit Boat Details"
                                titleBottom={'No Telemetry Available'}
                            >
                                {editBoat && (
                                    <Grid item xs={12} md={12}>
                                        <Button
                                            onClick={openEdit}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            style={{
                                                height: 55,
                                            }}
                                        >
                                            Edit Boat Info
                                        </Button>
                                    </Grid>
                                )}
                            </FormSection>
                            <Grid item xs={12} md={12}>
                                <FormSection title={`${fullHin}'s Details`}>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        sx={{ marginBottom: 5 }}
                                    >
                                        <TextField
                                            label={'Signal Profile'}
                                            value={
                                                boatModel?.signalProfile
                                                    ?.profileName
                                            }
                                            disabled={true}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        sx={{ marginBottom: 5 }}
                                    >
                                        <TextField
                                            label={'Nickname'}
                                            value={nickname || 'None'}
                                            disabled={true}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                </FormSection>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export default BoatConfigurationInsights
