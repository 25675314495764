import {
    Autocomplete,
    Button,
    Grid,
    TextField,
    Typography,
    Checkbox,
    FormControlLabel,
    Chip,
    Tooltip,
    Switch,
} from '@mui/material'

import { useEffect, useReducer, Fragment } from 'react'
import { useToastMessage } from '../../../../context/ToastPopUpContext'
import { SaveButton } from '../../../UniversalComponents'
import { FormSection } from '../../../UniversalComponents'
import { editUserProfile } from '../../../../apiRoutes'
import { Autocomplete as UniversalAutocomplete } from '../../../UniversalComponents'
import { getManufacturers } from '../../../../apiRoutes'
import responseError from '../../../../context/responseError/responseError'
import EditIcon from '@mui/icons-material/Edit'
import StickyNote2Icon from '@mui/icons-material/StickyNote2'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import { getBoats } from '../../../../apiRoutes'
import { useNavMenu } from '../../../../context/NavMenuContext'
import { useAuth } from '../../../../context/AuthenticationContext'

interface UserProfileFormData {
    _id: string
    id: string
    userProfileName: string
    manufacturer: object
    userProfileAssignedManufacturers: object[]
    userProfilePermissions: object
}
interface UserProfileFormTypes {
    row: UserProfileFormData
    formDescription?: string
    userFormPermissionCheck?: boolean
    userFormPermissionBlockCheck?: boolean
    onClose?: any
    saveCache?: any
    minimizeModal?: () => void
    readOnly?: boolean
}

const UserProfileForm = ({
    row,
    formDescription,
    onClose = () => {},
    saveCache = () => {},
    minimizeModal,
    userFormPermissionBlockCheck,
    userFormPermissionCheck,
    readOnly,
}: UserProfileFormTypes) => {
    const currentUser = useAuth()
    const auth = currentUser.auth
    const setToastMessage = useToastMessage()
    const { navMenuLinks } = useNavMenu()
    const defaultState = {
        formData: {
            id: row._id || '',
            _id: row._id || '',
            userProfileName: row?.userProfileName || '',
            manufacturer: row?.manufacturer || {},
            userProfileAssignedManufacturers:
                row?.userProfileAssignedManufacturers || [],
            userProfilePermissions: row?.userProfilePermissions || {},
        },
        formValidation: {
            isFormDirty: false,
            isFormValid: false,
        },
        manufacturerOptions: [],
        manufacturers: [],
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const { formData, formValidation, manufacturers } = state

    const {
        id,
        _id,
        userProfileName,
        manufacturer,
        userProfilePermissions,
        userProfileAssignedManufacturers,
    } = formData

    const { isFormDirty, isFormValid } = formValidation

    const handleSubmit = async () => {
        try {
            let parsedFormData = {
                ...formData,
            }
            let res: any

            res = await editUserProfile(parsedFormData)
            if (res) {
                const { data, status } = res

                if (data && status === 200) {
                    setToastMessage(
                        `User Profile: ${data.userProfileName} was ${
                            !id ? 'created' : 'edited'
                        } successfully`,
                        'success'
                    )

                    onClose(row.userProfileName, data)
                } else {
                    responseError(res, row)
                }
            }
        } catch (err: any) {
            setToastMessage(err.message, 'error')
        }
    }

    const handleReset = () =>
        setState({
            formData: defaultState.formData,

            formValidation: defaultState.formValidation,
        })

    const handleChange = (e: any) => {
        const { name, value } = e.target

        setState({
            formData: { ...formData, [name]: value },
            formValidation: { ...formValidation, isFormDirty: true },
        })
    }

    const validateOnBlur = (e: any) => {
        const { name, value } = e.target

        if (value !== '') {
            setState({
                formValidation: {
                    ...formValidation,
                    [name + 'Validation']: false,
                    isFormDirty: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    [name + 'Validation']: true,
                    isFormDirty: true,
                },
            })
        }
    }

    const getData = async () => {
        let manufacturerOptions: any = []
        let manufacturers: any = []
        try {
            let data = await getManufacturers(true)
            let fleetData = await getBoats(true)
            if (data && data.length !== 0) {
                manufacturerOptions = data.map(
                    ({ manufacturerName }) => manufacturerName
                )
                manufacturers = data.map(({ _id, manufacturerName }) => ({
                    _id,
                    manufacturerName,
                }))
                setState({
                    manufacturerOptions,
                    manufacturers,
                })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
        }
    }

    const handleUserPermissionsChange = (bool, name, type) => {
        setState({
            formValidation: {
                ...formValidation,
                isFormDirty: true,
            },
            formData: {
                ...formData,
                userProfilePermissions: {
                    ...userProfilePermissions,
                    [name]: {
                        ...userProfilePermissions[name],
                        [type]: bool,
                        read: bool ? true : false,
                    },
                },
            },
        })
    }

    useEffect(() => {
        if (!readOnly) {
            getData()
        }
    }, [])

    useEffect(() => {
        if (readOnly) {
            setState({
                formData: {
                    id: row._id || '',
                    userProfileName: row?.userProfileName || '',
                    manufacturer: row?.manufacturer || {},
                    userProfileAssignedManufacturers:
                        row?.userProfileAssignedManufacturers || [],
                    userProfilePermissions: row?.userProfilePermissions || {},
                },
                formValidation: {
                    isFormDirty: false,
                    isFormValid: false,
                },
                manufacturerOptions: [],
                manufacturers: [],
            })
        }
    }, [row])

    useEffect(() => {
        if (isFormDirty) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [isFormDirty])

    return (
        <Grid container spacing={2} sx={{ marginTop: 8 }}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={!readOnly ? 5 : 12}>
                        <FormSection
                            title="User Profile Info"
                            titleAlignment="center"
                        >
                            {!readOnly && (
                                <Grid item xs={12}>
                                    <TextField
                                        required={true}
                                        label="User Profile Name"
                                        name="userProfileName"
                                        fullWidth
                                        onChange={handleChange}
                                        value={userProfileName}
                                        onBlur={validateOnBlur}
                                        disabled={readOnly}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Autocomplete
                                    multiple
                                    id="userProfileAssignedManufacturers"
                                    value={userProfileAssignedManufacturers}
                                    onBlur={(e) => {
                                        setState({
                                            formValidation: {
                                                ...formValidation,
                                                isFormDirty: true,
                                            },
                                        })
                                        validateOnBlur({
                                            target: {
                                                name: 'userProfileAssignedManufacturers',
                                                value:
                                                    userProfileAssignedManufacturers.length ===
                                                    0
                                                        ? ''
                                                        : true,
                                            },
                                        })
                                    }}
                                    onChange={(event, newValue) => {
                                        const data = {
                                            target: {
                                                name: 'userProfileAssignedManufacturers',
                                                value: newValue,
                                            },
                                        }

                                        handleChange(data)
                                    }}
                                    options={[
                                        ...userProfileAssignedManufacturers,
                                        ...manufacturers.filter(
                                            ({ manufacturerName }) =>
                                                !userProfileAssignedManufacturers.find(
                                                    (brand) =>
                                                        brand.manufacturerName ===
                                                        manufacturerName
                                                )
                                        ),
                                    ]}
                                    getOptionLabel={(option) =>
                                        option.manufacturerName
                                    }
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option: any, index) => (
                                            <Chip
                                                {...getTagProps({
                                                    index,
                                                })}
                                                label={option.manufacturerName}
                                                disabled={
                                                    userProfileAssignedManufacturers.indexOf(
                                                        index
                                                    ) !== -1 || readOnly
                                                }
                                                onDelete={(e) => {
                                                    getTagProps({
                                                        index,
                                                    }).onDelete(e)
                                                    setState({
                                                        formValidation: {
                                                            ...formValidation,
                                                            isFormDirty: true,
                                                        },
                                                    })
                                                }}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="userProfileAssignedManufacturers"
                                            label="User Profile Assigned Manufacturers"
                                            placeholder="User Profile Assigned Manufacturers"
                                            disabled={readOnly}
                                        />
                                    )}
                                    disabled={readOnly}
                                />
                            </Grid>
                        </FormSection>
                    </Grid>

                    {auth?.userProfile?.superAdmin && (
                        <Grid item md={!readOnly ? 7 : 12} xs={12}>
                            <FormSection
                                title="User Permissions"
                                titleAlignment="center"
                            >
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        {navMenuLinks.map(
                                            ({ children }) =>
                                                children &&
                                                children.map(({ name }) => (
                                                    <Fragment key={name}>
                                                        <Grid
                                                            item
                                                            md={6}
                                                            xs={6}
                                                        >
                                                            {name}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            md={3}
                                                            xs={3}
                                                        >
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={
                                                                            userProfilePermissions &&
                                                                            userProfilePermissions[
                                                                                name
                                                                            ] &&
                                                                            userProfilePermissions[
                                                                                name
                                                                            ]
                                                                                ?.read
                                                                                ? userProfilePermissions[
                                                                                      name
                                                                                  ]
                                                                                      ?.read
                                                                                : false
                                                                        }
                                                                        onChange={(
                                                                            event
                                                                        ) => {
                                                                            const isChecked =
                                                                                event
                                                                                    .target
                                                                                    .checked

                                                                            handleUserPermissionsChange(
                                                                                isChecked,
                                                                                name,
                                                                                'read'
                                                                            )
                                                                        }}
                                                                        name={`${name}.read`}
                                                                        icon={
                                                                            <StickyNote2Icon />
                                                                        }
                                                                        checkedIcon={
                                                                            <DoneAllIcon />
                                                                        }
                                                                    />
                                                                }
                                                                label="Read"
                                                                disabled={
                                                                    readOnly
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            md={2}
                                                            xs={3}
                                                        >
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={
                                                                            userProfilePermissions &&
                                                                            userProfilePermissions[
                                                                                name
                                                                            ] &&
                                                                            userProfilePermissions[
                                                                                name
                                                                            ]
                                                                                ?.write
                                                                                ? userProfilePermissions[
                                                                                      name
                                                                                  ]
                                                                                      ?.read &&
                                                                                  userProfilePermissions[
                                                                                      name
                                                                                  ]
                                                                                      ?.write
                                                                                : false
                                                                        }
                                                                        onChange={(
                                                                            event
                                                                        ) => {
                                                                            const isChecked =
                                                                                event
                                                                                    .target
                                                                                    .checked

                                                                            handleUserPermissionsChange(
                                                                                isChecked,
                                                                                name,
                                                                                'write'
                                                                            )
                                                                        }}
                                                                        name={`${name}.write`}
                                                                        icon={
                                                                            <EditIcon />
                                                                        }
                                                                        checkedIcon={
                                                                            <DoneAllIcon />
                                                                        }
                                                                    />
                                                                }
                                                                label="Write"
                                                                disabled={
                                                                    readOnly
                                                                }
                                                            />
                                                        </Grid>
                                                    </Fragment>
                                                ))
                                        )}
                                    </Grid>
                                </Grid>
                            </FormSection>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            {!readOnly && (
                <Grid item xs={12}>
                    <SaveButton
                        handleSubmit={handleSubmit}
                        handleReset={handleReset}
                        disabledSave={!isFormValid}
                        disabledReset={!isFormDirty}
                        saveTitle={'Save & Close'}
                    />
                </Grid>
            )}
        </Grid>
    )
}

export default UserProfileForm
