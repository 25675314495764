import { useReducer, useEffect } from 'react'
import {
    UniversalCookieCrumb,
    DataGridLayout,
    TabPanel,
} from '../../UniversalComponents'
import { useMinimizer } from '../../../context/MinimizeContext'
import UserAdmin from './UserAdmin'
import UserProfiles from './UserProfiles'
import UserNotificationSubscription from './UserNotificationSubscription'

import { Grid, useTheme, AppBar, Tab, Tabs } from '@mui/material'
import { useAuth } from '../../../context/AuthenticationContext'

const Users = () => {
    const reducer = (state, newState) => ({ ...state, ...newState })
    const { auth } = useAuth()
    const defaultState = {
        currentTab: 0,
        tabName: '',
    }

    const [state, setState] = useReducer(reducer, defaultState)

    const { currentTab, tabName } = state
    const tabs =
        auth && auth?.userProfile && auth?.userProfile?.superAdmin
            ? ['User Admin', 'User Profiles', 'User Notification Subscriptions']
            : ['User Admin', 'User Notification Subscriptions']

    const setCurrentTab = (event: React.SyntheticEvent, newValue: number) =>
        setState({ currentTab: newValue, tabName: tabs[newValue] })

    const theme = useTheme()
    const { restoredData } = useMinimizer()

    useEffect(() => {
        if (
            restoredData?.id !== undefined &&
            restoredData?.tab === 'useradmin'
        ) {
            setState({ currentTab: 0 })
        }
        if (
            restoredData?.id !== undefined &&
            restoredData?.tab === 'usernotificationsubscription'
        ) {
            setState({ currentTab: 1 })
        }
    }, [restoredData])

    return (
        <DataGridLayout
            cookieCrumbs={<UniversalCookieCrumb customTitle={tabName} />}
            tabBar={
                <Tabs
                    value={currentTab}
                    onChange={setCurrentTab}
                    indicatorColor="secondary"
                    textColor="primary"
                    variant="scrollable"
                    aria-label="Osmosis"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                >
                    {tabs.map((label) => (
                        <Tab label={label} />
                    ))}
                </Tabs>
            }
            dataGrid={
                <>
                    <TabPanel
                        value={currentTab}
                        index={0}
                        dir={theme.direction}
                    >
                        <UserAdmin />
                    </TabPanel>
                    {tabs.length === 3 && (
                        <TabPanel
                            value={currentTab}
                            index={1}
                            dir={theme.direction}
                        >
                            <UserProfiles />
                        </TabPanel>
                    )}
                    <TabPanel
                        value={currentTab}
                        index={tabs?.length === 3 ? 2 : 1}
                        dir={theme.direction}
                    >
                        <UserNotificationSubscription />
                    </TabPanel>
                </>
            }
        />
    )
}

export default Users
