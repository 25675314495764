import { useReducer, useEffect } from 'react'
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Select,
    Tooltip,
    Divider,
} from '@mui/material'
import {
    LicenseInfo,
    useGridApiRef,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    useGridApiContext,
} from '@mui/x-data-grid-pro'
import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro'
import { FormSection } from '.'
import {
    DensityLarge,
    DensityMedium,
    DensitySmall,
    Save as SaveIcon,
    Search as SearchIcon,
    CheckCircle,
} from '@mui/icons-material/'

LicenseInfo.setLicenseKey(
    'd66ce7d51282a36b51fe929c5b9dd71aTz0xMDc3NzUsRT0xNzcwOTQwNzk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI='
)

const reducer = (state, newState) => ({ ...state, ...newState })

interface pinnedColumnsProps {
    left: string[]
    right: string[]
}

interface Props {
    data: any
    visibleColumns: any
    getRowData?: (data) => void
    columnWidths?: {
        [key: string]: number
    }
    getColumnHeaderVisibility?: (data) => void
    columnsAvailable?: any
    onRowSelectionModelChange?: any
    checkboxSelection?: boolean
    customColumnNames?: string[]
    pinnedColumns?: pinnedColumnsProps
    onColumnOrderChange?: any
    onUpdatedColumnsChange?: (onColumnOrderChange: any) => void
    columns?: any
    apiColumns?: any
    savePreferences?: any
    columnVisibilityModel?: any
    apiDensity?: any
    onDensityChange?: (density: string) => void
    sx?: {}
}

const UniversalDataTable = ({
    data,
    visibleColumns,
    getRowData = (row) => row,
    columnWidths,
    getColumnHeaderVisibility = (column) => column,
    columnsAvailable,
    onRowSelectionModelChange,
    checkboxSelection = true,
    customColumnNames = [],
    pinnedColumns = { left: [], right: [] },
    onColumnOrderChange = () => {},
    onUpdatedColumnsChange,
    apiColumns,
    savePreferences,
    columnVisibilityModel,
    apiDensity,
    onDensityChange,
    sx,
}: Props) => {
    const defaultState = {
        columns: [],
        rows: [],
        newColumns: apiColumns,
        defaultDensity: apiDensity || 'standard',
        anchorEl: null,
        densityIcon:
            apiDensity === 'standard' ? (
                <DensityMedium />
            ) : apiDensity === 'compact' ? (
                <DensitySmall />
            ) : apiDensity === 'comfortable' ? (
                <DensityLarge />
            ) : (
                <DensityMedium />
            ),
    }
    const [state, setState] = useReducer(reducer, defaultState)
    const { rows, columns, newColumns, defaultDensity, anchorEl, densityIcon } =
        state

    const apiRef = useGridApiRef()

    useEffect(() => {
        if (data && data.length !== 0) {
            let filteredColumns: any

            if (visibleColumns && visibleColumns.length !== 0) {
                filteredColumns = Object.keys(data[0])
                    .map((key: any) => {
                        return {
                            field: key,
                            headerName: key?.toUpperCase(),
                            width: columnWidths ? columnWidths[key] : 150,
                            type:
                                key !== 'engineCalibrationActive'
                                    ? 'string'
                                    : 'boolean',
                        }
                    })
                    .filter(
                        (key) => visibleColumns.includes(key.field) && key.field
                    )

                const filteredRows = data.map((values) => ({
                    ...values,
                    key: values.id,
                }))

                if (customColumnNames && customColumnNames.length !== 0) {
                    filteredColumns = filteredColumns.map((column, index) => ({
                        ...column,
                        headerName: customColumnNames[index].toUpperCase(),
                    }))
                }

                return setState({
                    columns: filteredColumns,
                    rows: filteredRows,
                })
            }

            const pageData = apiColumns
            if (apiColumns && apiColumns?.length !== 0) {
                filteredColumns = Object.keys(data[0])
                    .map((key) => {
                        pageData.find((col) => col.field === key)

                        return {
                            field: key,
                            headerName: pageData.headerName,
                            width: pageData.width,
                            isVisible: pageData.isVisible,
                        }
                    })
                    .filter(({ field }) =>
                        pageData.some((col) => col.field === field)
                    )

                const filteredRows = data.map((values) => ({
                    ...values,
                    key: values.id,
                }))

                if (pageData && pageData.length !== 0) {
                    filteredColumns = pageData.map((column) => ({
                        ...column,
                        headerName: column.headerName,
                    }))
                }

                return setState({
                    ...state,
                    columns: filteredColumns,
                    rows: filteredRows,
                })
            }
        } else if (visibleColumns.length === 0) {
            let filteredColumns = Object.keys(data[0])
                .map((key) => ({
                    field: key,
                    headerName: key?.toUpperCase(),
                    width: columnWidths ? columnWidths[key] : 150,
                }))
                .filter(({ field }) => visibleColumns.includes(field) && field)

            const filteredRows = data.map((values) => ({
                ...values,
                key: values.id,
            }))

            if (customColumnNames && customColumnNames.length !== 0) {
                filteredColumns = filteredColumns.map((column, index) => ({
                    ...column,
                    headerName: customColumnNames[index].toUpperCase(),
                }))
            }

            return setState({
                columns: filteredColumns,
                rows: filteredRows,
            })
        }
    }, [data, visibleColumns, columnsAvailable])

    const handleColumnOrderChange = (params, type) => {
        if (onUpdatedColumnsChange) {
            let updatedColumns = [...newColumns]

            if (type === 'resize') {
                const { field, headerName, width } = params.colDef
                updatedColumns = apiRef.current
                    .getAllColumns()
                    .map((col) =>
                        col.field === field
                            ? { ...col, width, headerName }
                            : col
                    )
            } else if (type === 'order') {
                updatedColumns = apiRef.current
                    .getAllColumns()
                    .map((col: any) => ({
                        field: col.field,
                        headerName: col.headerName,
                        width: col.width,
                        isVisible: col.isVisible,
                        type: col.type,
                    }))
                    .filter((col: any) => col.field !== '__check__')
            }

            setState({ ...state, newColumns: updatedColumns })
            onUpdatedColumnsChange(updatedColumns)
        }
    }

    const search = () => {
        apiRef.current.showFilterPanel()
    }

    const handleClick = (event: any) => {
        const button = event.currentTarget
        const rect = button.getBoundingClientRect(event)
        setState({
            anchorEl: {
                top: rect.bottom,
                left: rect.left,
                width: event.currentTarget.offsetWidth,
                height: event.currentTarget.offsetHeight,
            },
        })
    }

    const handleClose = () => {
        setState({ anchorEl: null })
    }

    const handleDensityChange = (newDensity) => {
        setState({ defaultDensity: newDensity })
        apiRef.current.setDensity(newDensity)
        if (newDensity === 'comfortable') {
            setState({ densityIcon: <DensityLarge /> })
        }
        if (newDensity === 'standard') {
            setState({ densityIcon: <DensityMedium /> })
        }
        if (newDensity === 'compact') {
            setState({ densityIcon: <DensitySmall /> })
        }
        handleClose()

        if (onDensityChange) {
            onDensityChange(newDensity)
        }
    }

    const open = Boolean(anchorEl)

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <Grid item xs={12} md={12}>
                    <GridToolbarColumnsButton sx={{ fontSize: 14 }} />
                    {/* <GridToolbarFilterButton /> */}
                    {/* <GridToolbarDensitySelector /> */}

                    <Button
                        id="density-button"
                        aria-controls={open ? 'density-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        startIcon={densityIcon}
                    >
                        Density
                    </Button>
                    <Menu
                        id="density-menu"
                        anchorEl={anchorEl ? () => document.body : null}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'density-button',
                        }}
                        anchorOrigin={{
                            vertical: anchorEl?.top || 'center',
                            horizontal: anchorEl?.left || 'center',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        sx={{
                            marginTop: 15,
                            marginLeft: 8,
                        }}
                    >
                        <MenuItem
                            onClick={() => handleDensityChange('compact')}
                        >
                            <ListItemIcon>
                                <DensitySmall />
                            </ListItemIcon>
                            <ListItemText>Compact</ListItemText>
                        </MenuItem>
                        <MenuItem
                            onClick={() => handleDensityChange('standard')}
                        >
                            <ListItemIcon>
                                <DensityMedium />
                            </ListItemIcon>
                            <ListItemText>Standard</ListItemText>
                        </MenuItem>

                        <MenuItem
                            onClick={() => handleDensityChange('comfortable')}
                        >
                            <ListItemIcon>
                                <DensityLarge />
                            </ListItemIcon>
                            <ListItemText>Comfortable</ListItemText>
                        </MenuItem>
                    </Menu>

                    <Button
                        onClick={search}
                        title="Search"
                        sx={{ color: 'primary' }}
                        startIcon={<SearchIcon />}
                    >
                        Search
                    </Button>
                    <Button
                        startIcon={<SaveIcon />}
                        onClick={savePreferences}
                        disabled={savePreferences ? false : true}
                        sx={{ color: 'primary' }}
                    >
                        Save Table Preferences
                    </Button>
                </Grid>
            </GridToolbarContainer>
        )
    }

    return (
        <Grid container sx={{ marginTop: -2, ...sx }} spacing={2}>
            <Grid item xs={12}>
                <DataGridPro
                    apiRef={apiRef}
                    columns={columns}
                    rows={rows}
                    onRowSelectionModelChange={onRowSelectionModelChange}
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 50,
                            },
                        },
                    }}
                    onCellClick={({ field, row }) => {
                        if (field !== '__check__') {
                            getRowData(row)
                        }
                    }}
                    onColumnVisibilityModelChange={(e) =>
                        getColumnHeaderVisibility(e)
                    }
                    pageSizeOptions={[10, 25, 50, 100]}
                    checkboxSelection={checkboxSelection}
                    disableRowSelectionOnClick
                    columnBuffer={0}
                    autosizeOnMount
                    pinnedColumns={pinnedColumns}
                    onColumnOrderChange={(params) =>
                        handleColumnOrderChange(params, 'order')
                    }
                    onColumnResize={(params) =>
                        handleColumnOrderChange(params, 'resize')
                    }
                    disableColumnReorder={false}
                    columnVisibilityModel={columnVisibilityModel}
                    slots={{
                        toolbar: CustomToolbar,
                        booleanCellTrueIcon: CheckCircle,
                    }}
                    disableColumnMenu={true}
                    density={defaultDensity}
                    sx={{
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        },
                        border: 'none',
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default UniversalDataTable
