import buildRoute from './buildRoute'

export const getCDNURLS = async (cdnType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'cdn-urls',
            data: { active: true, cdnType },
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        return console.error(err)
    }
}
