import { Grid, Button, Tooltip, IconButton } from '@mui/material'
import { UniversalReactSorter } from '../../../UniversalComponents'
import { Autocomplete } from '../../../UniversalComponents'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

const SignalCategoryTab = ({
    index,
    boatInsightCategory,
    rawSignals,
    filteredRawSignalList,
    handleRawSignalReorder,
    handleAddRawSignal,
    handleRemoveRawSignal,
    handleChange,
    selectedRawSignal,
}) => {
    return (
        <Grid container spacing={2}>
            {index === boatInsightCategory && (
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={10}>
                            <Autocomplete
                                options={filteredRawSignalList}
                                value={selectedRawSignal}
                                handleChange={handleChange}
                                label="Select Raw Signal"
                                name="selectedRawSignal"
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleAddRawSignal(index)}
                                disabled={!selectedRawSignal}
                                sx={{
                                    width: '100%',
                                    height: 55,
                                }}
                            >
                                Add Signal
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <UniversalReactSorter
                            list={rawSignals}
                            setList={(drag) =>
                                handleRawSignalReorder(index, drag)
                            }
                            group="fixed"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'left',
                                flexWrap: 'wrap',
                                marginTop: 5,
                                width: '100%',
                            }}
                        >
                            {rawSignals.map((signal, rawSignalIndex) => (
                                <div
                                    style={{
                                        background: '#222',
                                        margin: 2,
                                        padding: 10,
                                        cursor: 'grab',
                                    }}
                                    key={`signal-${signal}-${rawSignalIndex}`}
                                >
                                    <Tooltip title="Remove Signal">
                                        <IconButton
                                            aria-label="Remove Signal"
                                            onClick={() =>
                                                handleRemoveRawSignal(
                                                    index,
                                                    rawSignals,
                                                    signal.signalName
                                                )
                                            }
                                            sx={{
                                                position: 'relative',
                                                bottom: 2,
                                                right: 2,
                                            }}
                                        >
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </Tooltip>
                                    {signal.signalName}
                                </div>
                            ))}
                        </UniversalReactSorter>
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export default SignalCategoryTab
