import { useReducer, useEffect, useContext } from 'react'
import {
    UniversalDataTable,
    UniversalModal,
    UniversalToolBar,
    UniversalLoadingCircle,
    UniversalExcelExport,
    DataGridLayout,
    FormSection,
} from '../../../UniversalComponents'
import {
    Button,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import UniversalCookieCrumb from '../../../UniversalComponents/UniversalCookieCrumb'
import {
    PersonAdd as PersonAddIcon,
    Delete as DeleteIcon,
    Archive as ArchiveIcon,
    ArrowOutward as ArrowOutwardIcon,
    Restore,
} from '@mui/icons-material/'
import {
    getUserProfiles,
    editUserProfile,
    activateUserProfiles,
} from '../../../../apiRoutes'
import { handleActivation } from '../../../../utilities/functionTools'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { useMinimizer } from '../../../../context/MinimizeContext'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import {
    Authentication,
    useAuth,
} from '../../../../context/AuthenticationContext'
import { standardColumnMapper } from '../../../../utilities/functionTools'
import universalExcelExport from '../../../UniversalComponents/UniversalExcelExport'
import { permissionsCheck } from '../../../../utilities/functionTools'
import { usePermissions } from '../../../../context/AuthenticationContext'
import { useSocket } from '../../../../context/SocketContext'
import UserProfileForm from './UserProfileForm'

const today = new Date().toISOString().substring(0, 10)

const UserProfiles = () => {
    const { setUser, auth } = useAuth()
    const { username, userPreferences } = auth

    const defaultState = {
        open: false,
        row: {},
        formCache: {},
        data: [],
        openFileNamer: false,
        activateData: [],
        viewDeactivated: false,
        excelExportName: '',
        excelSelectedRows: [],
        manufacturers: [],
    }
    const setToastMessage = useContext(ToastMessage)
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const { setMinimized } = useMinimizer()
    const { socket } = useSocket()

    const {
        open,
        row,
        formCache,
        data,
        openFileNamer,
        excelExportName,
        activateData,
        viewDeactivated,
        excelSelectedRows,
    } = state
    const getData = async (active) => {
        let userProfiles: any = []
        try {
            userProfiles = await getUserProfiles(active)

            if (userProfiles && userProfiles.length !== 0) {
                userProfiles = standardColumnMapper(
                    defaultColumns,
                    userProfiles
                )
            }
            setState({ data: userProfiles })
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const handleActivateData = () => {
        handleActivation({
            activateData,
            data,
            returnKey: 'userProfileName',
            setState,
            getData,
            route: activateUserProfiles,
            viewDeactivated,
            setToastMessage,
            page: 'userProfiles',
        })
    }

    const setViewDeactivated = () => {
        setState({ viewDeactivated: !viewDeactivated })
    }

    useEffect(() => {
        getData(true)

        socket.on('userProfiles', (newData) => {
            if (newData) {
                getData(!viewDeactivated)
                setToastMessage(`User Profiles have just updated.`)
            }
        })
    }, [])

    useEffect(() => {
        getData(!viewDeactivated)
    }, [viewDeactivated])

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?.userProfileName) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({
                row: defaultState.row,
                open: !open,
            })
        }
        getData(true)
    }

    const openFileNamerModal = () => setState({ openFileNamer: !openFileNamer })
    const closeFileNamerModal = () =>
        setState({ openFileNamer: !openFileNamer })
    const handleTextFieldChange = (event: any) =>
        setState({ excelExportName: event.target.value })

    const buttons = [
        {
            buttonName: 'Add New User Profile',
            buttonIcon: <PersonAddIcon />,
            buttonFunction: setModalVisbility,
        },
        // {
        //     buttonName: 'Delete',
        //     buttonIcon: <DeleteIcon />,
        //     buttonFunction: () => {},
        // },
        {
            buttonName: !viewDeactivated ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleActivateData,
        },
        {
            buttonName: !viewDeactivated
                ? 'View Deactivated User Profiles'
                : 'View Active User Profiles',
            buttonIcon: <Restore />,
            buttonFunction: setViewDeactivated,
        },
        // {
        //     buttonName: 'Export',
        //     buttonIcon: ArrowOutwardIcon,
        //     buttonFunction: openFileNamerModal,
        // },
    ]

    const defaultColumns = ['userProfileName', 'manufacturer.manufacturerName']

    const visibleColumns = ['userProfileName', 'manufacturerName']

    const customColumnNames = ['User Profile Name', 'Manufacturer']

    const userPermissions = usePermissions()

    return (
        <Grid container>
            <Grid container>
                <UniversalToolBar buttons={buttons} />
            </Grid>

            <Grid item xs={12}>
                <UniversalLoadingCircle
                    data={data}
                    reloadFunction={
                        !viewDeactivated
                            ? () => getData(true)
                            : () => getData(false)
                    }
                    customTimeoutMessage={
                        !viewDeactivated
                            ? 'No active user profiles available.'
                            : 'No deactivated user profiles available.'
                    }
                >
                    <UniversalDataTable
                        data={data}
                        visibleColumns={visibleColumns}
                        customColumnNames={customColumnNames}
                        getRowData={(tableRow) =>
                            setModalVisbility(tableRow, undefined)
                        }
                        onRowSelectionModelChange={(rowSelection) => {
                            setState({ activateData: rowSelection })
                        }}
                    />
                </UniversalLoadingCircle>
            </Grid>

            <UniversalModal
                title={
                    row.id
                        ? `User Profile: ${row.userProfileName}`
                        : 'Configure User Profile'
                }
                timeStamp={`${
                    row.userEmail
                        ? ` Modified By: ${row.updatedBy} ${
                              row.updateAt
                                  ? `| Modified Date ${row.updateAt}`
                                  : ''
                          }  `
                        : ` Created by: ${username} | Modified Date: ${today} `
                }`}
                open={open}
                onClose={(tableRow) => setModalVisbility(tableRow, undefined)}
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 5,
                }}
            >
                <UserProfileForm
                    row={row}
                    onClose={(tableRow: any, newData?: any) =>
                        setModalVisbility(tableRow, newData)
                    }
                />
            </UniversalModal>

            <UniversalModal
                title={'Download Excel File'}
                open={openFileNamer}
                onClose={closeFileNamerModal}
                fullScreen={false}
                wrapperStyle={{
                    margin: 'auto',
                    width: '80%',
                    marginTop: 5,
                }}
            >
                <FormSection title="To export this Excel file please specify a title ">
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={10} sx={{ my: 2 }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="XLXS File Name"
                                    onChange={handleTextFieldChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={2}>
                                <Tooltip title="Download Excel File">
                                    <IconButton
                                        onClick={() => {
                                            UniversalExcelExport(
                                                visibleColumns,
                                                customColumnNames,
                                                data,
                                                excelSelectedRows,
                                                `${excelExportName}.xlsx`
                                            )
                                            closeFileNamerModal()
                                        }}
                                    >
                                        <DownloadForOfflineIcon
                                            sx={{
                                                fontSize: 70,
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </FormSection>
            </UniversalModal>
        </Grid>
    )
}

export default UserProfiles
