import {
    Autocomplete,
    Button,
    Grid,
    TextField,
    Typography,
    Checkbox,
    FormControlLabel,
    Chip,
    Tooltip,
    Switch,
} from '@mui/material'

import { useContext, useEffect, useReducer, Fragment } from 'react'
import { useToastMessage } from '../../../../context/ToastPopUpContext'
import { useMinimizer } from '../../../../context/MinimizeContext'
import { SaveButton } from '../../../UniversalComponents'
import { useAuth } from '../../../../context/AuthenticationContext'
import { FormSection } from '../../../UniversalComponents'
import { addUser, editUser } from '../../../../apiRoutes/userRoutes'
import { createUser, getUserProfiles } from '../../../../apiRoutes'
import { Autocomplete as UniversalAutocomplete } from '../../../UniversalComponents'
import { getManufacturers } from '../../../../apiRoutes'
import { useNavMenu } from '../../../../context/NavMenuContext'
import responseError from '../../../../context/responseError/responseError'
import { UniversalGoogleMapsAc } from '../../../UniversalComponents/UniversalFormStyles'
import EditIcon from '@mui/icons-material/Edit'
import StickyNote2Icon from '@mui/icons-material/StickyNote2'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import { getBoats } from '../../../../apiRoutes'
import { Star, StarBorderOutlined } from '@mui/icons-material'
import UserProfileForm from '../UserProfiles/UserProfileForm'

interface UserFormFormData {
    id?: string
    _id?: string
    userEmail: string
    userFirstName?: string
    userLastName?: string
    userPhoneNumber?: string
    userAddress?: string
    updatedBy?: string
    updatedAt?: string
    activeManufacturer: { manufacturerName: '' }
    manufacturer?: []
    userRole?: string
    userProfile: any
    userPermissions?: string
    userBoats?: [
        {
            nickname: ''
            fullHin: ''
            _id: ''
        }
    ]
    favoriteBoat?: {}
    userPreferences: {
        imperial: false
    }
}
interface UserFormTypes {
    row: UserFormFormData
    formDescription?: string
    userFormPermissionCheck?: boolean
    userFormPermissionBlockCheck?: boolean
    onClose?: any
    saveCache?: any
    minimizeModal?: () => void
    userAccount?: boolean
}

const UserForm = ({
    row,
    formDescription,
    onClose = () => {},
    saveCache = () => {},
    minimizeModal,
    userAccount = false,
    userFormPermissionBlockCheck,
    userFormPermissionCheck,
}: UserFormTypes) => {
    const setToastMessage = useToastMessage()
    const { setMinimized, restoredData } = useMinimizer()
    const currentUser = useAuth()
    const { navMenuLinks } = useNavMenu()
    const auth = currentUser.auth
    const defaultState = {
        formData: {
            id: row._id || '',
            userEmail: row.userEmail || '',
            userFirstName: row.userFirstName || '',
            userLastName: row.userLastName || '',
            userPhoneNumber: row.userPhoneNumber || '',
            userAddress: row?.userAddress || '',
            password: '',
            manufacturer: row.manufacturer || [],
            activeManufacturer: row.activeManufacturer || '',
            userRole: row.userRole || '',
            userProfile: row?.userProfile || {},
            userPermissions: row.userPermissions || {},
            userBoats: row.userBoats || [],
            favoriteBoat: row.favoriteBoat || null,
            userPreferences: row.userPreferences || { imperial: false },
        },
        formValidation: {
            userEmailValidation: false,
            userFirstNameValidation: false,
            userLastNameValidation: false,
            userPhoneNumberValidation: false,
            userRoleValidation: false,
            manufacturerValidation: false,
            activeManufacturerValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },
        isFormDirty: false,
        isFormValid: false,
        closeModal: false,
        manufacturerNames: [],
        manufacturers: [],
        userProfiles: [],
        fleet: [],
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const {
        formData,
        manufacturerNames,
        manufacturers,
        formValidation,
        fleet,
        isEditMode,
        setIsEditMode,
        assignedManufacturers,
        userProfiles,
    } = state

    const {
        userEmailValidation,
        userFirstNameValidation,
        userLastNameValidation,
        userPhoneNumberValidation,
        userRoleValidation,
        manufacturerValidation,
        activeManufacturerValidation,
        isFormDirty,
        isFormValid,
    } = formValidation

    const {
        userEmail,
        userFirstName,
        userLastName,
        userPhoneNumber,
        password,
        activeManufacturer,
        manufacturer,
        userRole,
        userPermissions,
        userAddress,
        userBoats,
        favoriteBoat,
        userPreferences,
        userProfile,
    } = formData

    const { imperial } = userPreferences

    const handleSubmit = async () => {
        try {
            let parsedFormData = { ...formData }
            let res: any

            if (row.id || userAccount) {
                res = await editUser(parsedFormData)
                if (res) {
                    const { data, status } = res

                    if (data && status === 200) {
                        setToastMessage(
                            `User: ${data.userEmail} was edited successfully`,
                            'success'
                        )
                        userAccount
                            ? setState({
                                  formValidation: {
                                      ...formValidation,
                                      isFormDirty: false,
                                  },
                              })
                            : null

                        onClose(row.userEmail, data)
                    } else {
                        responseError(res, row)
                    }
                }
            } else {
                res = await addUser(parsedFormData)

                if (res) {
                    const { data, status } = res

                    if (data?.status === 'failed') {
                        setToastMessage(`User: ${data?.message}`, 'error')
                    } else if (data && status === 200) {
                        setToastMessage(
                            `User: ${data.userEmail} was added successfully`,
                            'success'
                        )
                        onClose(row.userEmail, data)
                    } else {
                        responseError(res, row)
                    }
                }
            }
        } catch (err) {
            console.error(err, 'error')
            setToastMessage(`Admin password is incorrect.`, 'error')
        }
    }

    const handleReset = () =>
        setState({
            formData: defaultState.formData,

            formValidation: defaultState.formValidation,
        })

    const handleChange = (e: any) => {
        const { name, value } = e.target
        if (name === 'userProfile') {
            setState({
                formData: {
                    ...formData,
                    [name]: userProfiles.find(
                        (userProfile) => userProfile.userProfileName === value
                    ),
                },
                formValidation: {
                    ...formValidation,
                    isFormDirty: true,
                },
            })
        } else if (name === 'activeManufacturer') {
            setState({
                formData: {
                    ...formData,
                    [name]: manufacturers.find(
                        (manufacturer) =>
                            manufacturer.manufacturerName === value
                    ),
                },
                formValidation: {
                    ...formValidation,
                    isFormDirty: true,
                },
            })
        } else {
            setState({
                formData: { ...formData, [name]: value },
            })
        }
    }

    useEffect(() => {
        const emailValid = () => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            return emailRegex.test(userEmail)
        }
        if (
            isFormDirty &&
            !userEmailValidation &&
            emailValid() &&
            !userFirstNameValidation &&
            !userLastNameValidation &&
            !userPhoneNumberValidation &&
            !manufacturerValidation &&
            !activeManufacturerValidation &&
            !userRoleValidation &&
            userRole &&
            manufacturer.length !== 0 &&
            !!activeManufacturer &&
            userEmail &&
            userFirstName &&
            userLastName &&
            userPhoneNumber
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        userEmailValidation,
        userFirstNameValidation,
        userLastNameValidation,
        userPhoneNumberValidation,
        userEmail,
        userFirstName,
        userLastName,
        userPhoneNumber,
        manufacturer,
        activeManufacturer,
        userRole,
        userRoleValidation,
        activeManufacturerValidation,
        manufacturerValidation,
    ])

    const validateOnBlur = (e: any) => {
        const { name, value } = e.target

        if (value !== '') {
            setState({
                formValidation: {
                    ...formValidation,
                    [name + 'Validation']: false,
                    isFormDirty: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    [name + 'Validation']: true,
                    isFormDirty: true,
                },
            })
        }
    }

    const getData = async () => {
        let manufacturerNames: any = []
        let manufacturers: any = []
        let fleet: any = []
        let userProfiles: any = []
        try {
            let data = await getManufacturers(true)
            let fleetData = await getBoats(true)
            let userProfilesData = await getUserProfiles(true)
            if (data && data.length !== 0) {
                manufacturerNames = data.map(
                    ({ manufacturerName }) => manufacturerName
                )
                manufacturers = data.map(({ _id, manufacturerName }) => ({
                    _id,
                    manufacturerName,
                }))
            }
            if (fleetData && fleetData.length !== 0) {
                fleet = fleetData.map((boat) => ({
                    nickname: boat.nickname,
                    fullHin: boat.fullHin,
                    _id: boat._id,
                }))
            }
            if (userProfilesData && userProfilesData.length !== 0) {
                userProfiles = userProfilesData.map(
                    (userProfile) => userProfile
                )
            }
            setState({
                manufacturerNames,
                manufacturers,
                fleet,
                userProfiles,
            })
        } catch (err: any) {
            setToastMessage(err, 'error')
        }
    }

    const handleUserPermissionsChange = (bool, name, type) => {
        setState({
            formValidation: {
                ...formValidation,
                isFormDirty: true,
            },
            formData: {
                ...formData,
                userPermissions: {
                    ...userPermissions,
                    [name]: {
                        ...userPermissions[name],
                        [type]: bool,
                        read: bool ? true : false,
                    },
                },
            },
        })
    }

    useEffect(() => {
        getData()
        saveCache(row)
    }, [])

    useEffect(() => {
        if (restoredData?.id !== undefined) {
            setState({ row: restoredData, open: true })
        }
    }, [restoredData])

    const userRoles = [
        'Super Admin',
        'Boat Admin',
        'Engine Admin',
        'Boat & Engine Admin',
        'Dealer',
        'Customer',
    ]

    const emailCheck = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(userEmail)
    }

    const handleUserPreferencesSubmit = async () => {
        try {
            let parsedFormData = { ...formData, ...row, userPreferences: {} }

            let res: any

            if (row.id || userAccount) {
                res = await editUser(parsedFormData)
                if (res) {
                    const { data, status } = res

                    if (data && status === 200) {
                        setToastMessage(
                            `User: ${data.userEmail} was edited successfully`,
                            'success'
                        )
                        setState({
                            formData: {
                                ...formData,
                                userPreferences: { imperial: false },
                            },
                            formValidation: {
                                ...formValidation,
                                isFormDirty: false,
                            },
                        })
                    } else {
                        responseError(res, row)
                    }
                }
            }
        } catch (err) {
            console.error(err, 'error')
            setToastMessage(`Admin password is incorrect.`, 'error')
        }
    }

    const handleResetUserPreferences = () =>
        setToastMessage(
            'Are you sure you want to reset user preferences?',
            'warning',
            handleUserPreferencesSubmit
        )

    console.log(userProfile, 'profile')

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginTop: 5 }}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        md={userFormPermissionBlockCheck ? 7 : 12}
                    >
                        <FormSection
                            title="User Information"
                            titleAlignment="center"
                        >
                            <Grid item md={row.id || row._id ? 12 : 7} xs={12}>
                                <TextField
                                    required={true}
                                    label="E-Mail"
                                    name="userEmail"
                                    fullWidth
                                    onChange={handleChange}
                                    value={userEmail}
                                    onBlur={validateOnBlur}
                                    disabled={userFormPermissionCheck}
                                    error={
                                        userEmailValidation === false &&
                                        userEmail
                                            ? !emailCheck()
                                            : userEmailValidation && !userEmail
                                    }
                                    helperText={
                                        userEmailValidation === false &&
                                        !userEmail
                                            ? userEmailValidation && !userEmail
                                            : !emailCheck()
                                            ? 'Please enter a valid Email Address.'
                                            : ''
                                    }
                                />
                            </Grid>

                            {!row.id && !row._id && (
                                <Grid item xs={5}>
                                    <TextField
                                        required={true}
                                        label="Enter users temporary password."
                                        name="password"
                                        fullWidth
                                        onChange={handleChange}
                                        value={password}
                                        type="password"
                                        onBlur={validateOnBlur}
                                        disabled={userFormPermissionCheck}
                                    />
                                </Grid>
                            )}
                            <Grid item md={4} xs={12}>
                                <TextField
                                    required={true}
                                    fullWidth
                                    label="First Name"
                                    name="userFirstName"
                                    onChange={handleChange}
                                    value={userFirstName}
                                    onBlur={validateOnBlur}
                                    error={
                                        userFirstNameValidation &&
                                        !userFirstName
                                    }
                                    helperText={
                                        userFirstNameValidation &&
                                        !userFirstName
                                            ? 'Please enter a First Name'
                                            : ''
                                    }
                                    disabled={userFormPermissionCheck}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    required={true}
                                    fullWidth
                                    label="Last Name"
                                    name="userLastName"
                                    onChange={handleChange}
                                    value={userLastName}
                                    onBlur={validateOnBlur}
                                    error={
                                        userLastNameValidation && !userLastName
                                    }
                                    helperText={
                                        userLastNameValidation && !userLastName
                                            ? 'Please enter a Last Name'
                                            : ''
                                    }
                                    disabled={userFormPermissionCheck}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    required={true}
                                    fullWidth
                                    label="Phone Number"
                                    name="userPhoneNumber"
                                    onChange={handleChange}
                                    value={userPhoneNumber}
                                    onBlur={validateOnBlur}
                                    error={
                                        userPhoneNumberValidation &&
                                        !userPhoneNumber
                                    }
                                    helperText={
                                        userPhoneNumberValidation &&
                                        !userPhoneNumber
                                            ? 'Please enter a Phone Number for the User'
                                            : ''
                                    }
                                    disabled={userFormPermissionCheck}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <UniversalGoogleMapsAc
                                    name="userAddress"
                                    value={userAddress}
                                    label="Address"
                                    fullWidth={true}
                                    clearFunction={() =>
                                        setState({
                                            formValidation: {
                                                ...formValidation,
                                                isFormDirty: true,
                                            },
                                            formData: {
                                                ...formData,
                                                userAddress: '',
                                            },
                                        })
                                    }
                                    onChange={(event) => {
                                        if (event?.address) {
                                            setState({
                                                formData: {
                                                    ...formData,
                                                    userAddress: event.address,
                                                },
                                                formValidation: {
                                                    ...formValidation,
                                                    isFormDirty: true,
                                                },
                                            })
                                        }
                                    }}
                                    disabled={userFormPermissionCheck}
                                />
                            </Grid>
                        </FormSection>
                        <FormSection
                            title="User Preferences"
                            titleAlignment="center"
                        >
                            <Grid item xs={12} md={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={() =>
                                                setState({
                                                    formData: {
                                                        ...formData,
                                                        userPreferences: {
                                                            ...userPreferences,
                                                            imperial: !imperial,
                                                        },
                                                    },
                                                    formValidation: {
                                                        ...formValidation,
                                                        isFormDirty: true,
                                                    },
                                                })
                                            }
                                            inputProps={{
                                                'aria-label': 'controlled',
                                            }}
                                            id="switch.imperial"
                                            name="switch.imperial"
                                            checked={imperial}
                                            key="switch.imperial"
                                        />
                                    }
                                    label={
                                        !imperial
                                            ? 'Metric Measurements'
                                            : 'Imperial Measurements'
                                    }
                                    key="switch.imperial"
                                />
                            </Grid>
                            {(row.id || userAccount) && (
                                <Grid item xs={12} md={12}>
                                    <Button
                                        onClick={handleResetUserPreferences}
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        sx={{
                                            height: 55,
                                        }}
                                    >
                                        Reset User Preferences
                                    </Button>
                                </Grid>
                            )}
                        </FormSection>
                        {userFormPermissionBlockCheck && (
                            <FormSection
                                title="User Assigned Boats"
                                titleAlignment="center"
                            >
                                <Grid item md={12} xs={12}>
                                    <Autocomplete
                                        multiple
                                        id="userBoats"
                                        value={userBoats}
                                        onBlur={() =>
                                            setState({
                                                formValidation: {
                                                    ...formValidation,
                                                    isFormDirty: true,
                                                },
                                            })
                                        }
                                        onChange={(event, newValue) => {
                                            const data = {
                                                target: {
                                                    name: 'userBoats',
                                                    value: newValue,
                                                },
                                            }

                                            handleChange(data)
                                        }}
                                        options={fleet}
                                        getOptionLabel={(option) =>
                                            option?.nickname
                                                ? option?.nickname
                                                : option.fullHin
                                        }
                                        renderTags={(tagValue, getTagProps) =>
                                            tagValue.map(
                                                (option: any, index) => (
                                                    <Tooltip
                                                        title={
                                                            favoriteBoat &&
                                                            favoriteBoat._id ===
                                                                option?._id
                                                                ? 'Click to unfavorite boat.'
                                                                : 'Click to favorite boat.'
                                                        }
                                                        key={`${option._id}-tooltip`}
                                                    >
                                                        <Chip
                                                            {...getTagProps({
                                                                index,
                                                            })}
                                                            label={
                                                                option?.nickname
                                                                    ? option?.nickname
                                                                    : option.shortHin
                                                            }
                                                            variant={
                                                                favoriteBoat &&
                                                                favoriteBoat._id ===
                                                                    option?._id
                                                                    ? 'filled'
                                                                    : 'outlined'
                                                            }
                                                            onDelete={(e) => {
                                                                getTagProps({
                                                                    index,
                                                                }).onDelete(e)
                                                                setState({
                                                                    formValidation:
                                                                        {
                                                                            ...formValidation,
                                                                            isFormDirty:
                                                                                true,
                                                                        },
                                                                })
                                                            }}
                                                            icon={
                                                                favoriteBoat &&
                                                                favoriteBoat._id ===
                                                                    option?._id ? (
                                                                    <Star />
                                                                ) : (
                                                                    <StarBorderOutlined />
                                                                )
                                                            }
                                                            disabled={
                                                                fleet.indexOf(
                                                                    index
                                                                ) !== -1
                                                            }
                                                            onClick={() =>
                                                                setState({
                                                                    formData: {
                                                                        ...formData,
                                                                        favoriteBoat:
                                                                            favoriteBoat &&
                                                                            favoriteBoat._id ===
                                                                                option?._id
                                                                                ? {}
                                                                                : option,
                                                                    },
                                                                })
                                                            }
                                                        />
                                                    </Tooltip>
                                                )
                                            )
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                name="userBoats"
                                                label="Assigned Boats"
                                                placeholder="Assigned Boats"
                                            />
                                        )}
                                    />
                                </Grid>
                            </FormSection>
                        )}
                    </Grid>
                    {userFormPermissionBlockCheck && (
                        <Grid item xs={12} md={5}>
                            <FormSection
                                title="User Profile"
                                titleAlignment="center"
                            >
                                <Grid item xs={12}>
                                    <UniversalAutocomplete
                                        options={userProfiles.map(
                                            ({ userProfileName }) =>
                                                userProfileName
                                        )}
                                        value={userProfile?.userProfileName}
                                        handleChange={handleChange}
                                        required={true}
                                        label="User Profile"
                                        name="userProfile"
                                        onBlur={validateOnBlur}
                                        disabled={
                                            !auth?.userProfile?.superAdmin
                                        }
                                        helperText={
                                            userRoleValidation
                                                ? 'Please select a user profile.'
                                                : ''
                                        }
                                        error={userRoleValidation}
                                        clearFunction={() =>
                                            setState({
                                                formValidation: {
                                                    ...formValidation,
                                                    isFormDirty: true,
                                                },
                                                formData: {
                                                    ...formData,
                                                    userProfile: {},
                                                },
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ marginTop: -8 }}>
                                    {userProfile &&
                                        userProfile?.userProfileName && (
                                            <UserProfileForm
                                                row={userProfile}
                                                onClose={() => {}}
                                                readOnly={true}
                                            />
                                        )}
                                </Grid>
                            </FormSection>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <SaveButton
                    handleSubmit={handleSubmit}
                    handleReset={handleReset}
                    disabledSave={!isFormValid}
                    disabledReset={!isFormDirty}
                    saveTitle={!userAccount ? 'Save & Close' : 'Save'}
                />
            </Grid>
        </Grid>
    )
}

export default UserForm
