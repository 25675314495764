import { Grid, Tabs, Tab } from '@mui/material'
import { FormSection } from '../../../../UniversalComponents'
import { useReducer } from 'react'
const BoatConfigInsightsTelemetry = ({ boatInsights, telemetry }) => {
    const tabProps = (index: number, category: string) => {
        return {
            id: `tab-${category}-${index}`,
            key: `tab-${category}-${index}`,
            'aria-controls': `tabpanel-${category}-${index}`,
        }
    }
    const defaultState = {
        boatInsightCategory: 0,
        boatInsightCategories:
            boatInsights && boatInsights?.length !== 0
                ? boatInsights?.map(({ category }, index) => (
                      <Tab
                          label={category}
                          iconPosition="start"
                          {...tabProps(index, category)}
                      />
                  ))
                : [],
    }
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const { boatInsightCategory, boatInsightCategories } = state
    const setCurrentTab = (event: React.SyntheticEvent, newValue: number) => {
        setState({
            boatInsightCategory: newValue,
        })
    }
    return (
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <FormSection
                    title="Boat Insight Telemetry"
                    titleAlignment="left"
                >
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ marginBottom: 5 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={8}>
                                        <Tabs
                                            value={boatInsightCategory}
                                            onChange={setCurrentTab}
                                            aria-label="Category Tabs"
                                        >
                                            {boatInsightCategories}
                                        </Tabs>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {/* {Removing container or changing into an item will cause box creep} */}
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ marginLeft: -1 }}
                                >
                                    {boatInsights?.map((boatInsight, index) => {
                                        return (
                                            index === boatInsightCategory &&
                                            boatInsight.rawSignals.map(
                                                (signal, rawSignalIndex) => (
                                                    <div
                                                        style={{
                                                            background: '#222',
                                                            margin: 2,
                                                            padding: 10,
                                                        }}
                                                        key={`signal-${signal}-${rawSignalIndex}`}
                                                    >
                                                        {signal.signalName}:{' '}
                                                        {
                                                            <span
                                                                style={{
                                                                    color: 'green',
                                                                }}
                                                            >
                                                                {telemetry[
                                                                    signal
                                                                        .signalName
                                                                ]
                                                                    ? telemetry[
                                                                          signal
                                                                              .signalName
                                                                      ]
                                                                    : 'No Value'}
                                                            </span>
                                                        }
                                                    </div>
                                                )
                                            )
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}></Grid>
                </FormSection>
            </Grid>
        </Grid>
    )
}

export default BoatConfigInsightsTelemetry
